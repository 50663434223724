import PageTitle from '../../components/pages/pageTitle'
import Adress from '../../components/pages/address'
import ContactComp from '../../components/pages/contactComp'
const Contact = () => {
    return (
        <div>
            <PageTitle title='contact' value='Əlaqə'/>
            <ContactComp />
            <Adress />
        </div>
    )
}

export default Contact
