import './index.scss'
import Sprakle from '../../../assets/images/sparkle.svg'

const ShortServices = () => {
    const services = ['Breket', 'RPA apparat', 'Sanders apparat', 'Twin Block apparat', 'Frenkel apparat',
        'Bertoni qıfılı ilə genişləndirici aparat', 'Sadə genişləndirici apparat',
        'Metal keramika dişlərin hazırlanması', 'Zirkon dişlərin hazırlanması',
        'Vinirlər', 'Presskeramika', 'İnlay sistem', 'Onlay sistem', 'Overlay sistem', 'Diş daşların təmizlənməsi -Skeyling',
        'Diş ərpinin təmizlənməsi -Air Flow', 'Dişlərin Ağardılması', 'Estetik restovrasiya ', 'Plomblar',
        'Pulpitin, periodontitin və s. müalicəsi', 'Damağ müalicəsi müasir metodlarla ']
    return (
        <div className="short-services">
            <marquee>
                <ul>
                    {services.map(item => (
                        <span className="flex align-items-center gap-1" key={item}>
                            <img src={Sprakle} alt="32 Gözəl"/>
                            {item}
                        </span>
                    ))}
                </ul>
            </marquee>
        </div>
    )
}

export default ShortServices
