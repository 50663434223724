import {Link} from 'react-router-dom'
import {FiChevronRight} from 'react-icons/fi'
import './pageTitle.scss'
const pageTitle = ({title, value}) => {
    return (
        <div className='page-title'>
            <div className='breadcrumbs'>
                <Link to='/' className='link'>Ana səhifə</Link>
                <FiChevronRight />
                {
                    title === 'doctors' && <div className={title === 'doctors' ? 'link main' : 'link'}>Həkimlər</div>
                }
                {
                    title === 'doctor_id' && <Link to='/doctors' className={title === 'doctors' ? 'link main' : 'link'}>Həkimlər</Link>
                }
                {
                    title === 'doctor_id' && <FiChevronRight />
                }
                <div className='link main'>{value}</div>

            </div>
            {
                title === 'doctors' && <div className="title"><span>Bizim</span> Həkimlərimiz</div>
            }
            {
                title === 'doctor_id' && <div className="title mob">Haqqımızda</div>
            }
            { title !== 'doctor_id' && <div className="title">{value}</div>}
        </div>
    )
}

export default pageTitle
