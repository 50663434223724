import './index.scss'

import 'react-toastify/dist/ReactToastify.css'
import RandevuForm from '../randevuForm'
const Randevu = () => {
    return (
        <div className="online-randevu" id="online-randevu">
            <div className="content">
                <div className="left">
                    <p>Sağlam və gözəl gülüşlər üçün onlayn növbə alın.</p>
                </div>
                <RandevuForm />
            </div>
        </div>
    )
}

export default Randevu
