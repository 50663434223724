import EduWork from '../eduWork'
import './work.scss'
const Work = ({work}) => {
    return (
        <div className='work'>
            <EduWork education={work} info='İş Təcrübəsi:' />
        </div>
    )
}

export default Work
