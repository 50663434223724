import DoctorCard from '../doctorCard'
import './list.scss'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {useEffect, useState} from 'react'
const DoctorsList = () => {
    const [doctorList, setDoctorList] = useState([])
    const getDoctorList = async() => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/doctors`)
        setDoctorList(data?.data?.data)
    }
    useEffect(() => {
        getDoctorList()
    }, [])
    return (
        <div className='cart-list'>
            {
                doctorList.map(doctor => (
                    <Link to={`/doctors/${doctor.id}`} style={{textDecoration:'none'}} key={doctor.id}><DoctorCard doctor={doctor} /></Link>
                ))
            }
        </div>
    )
}
export default DoctorsList
