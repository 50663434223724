import PageTitle from '../../components/pages/pageTitle'
import AboutUs from '../../components/pages/about-us'
import Randevu from '../../components/pages/randevu'
import Adress from '../../components/pages/address'
const About = () => {
    return (
        <div>
            <PageTitle title='about' value='Haqqımızda'/>
            <AboutUs />
            <Randevu />
            <Adress />
        </div>
    )
}

export default About
