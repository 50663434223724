import React from 'react'
const Address = () => {
    return (
        <div className='address'>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1519.8436421603546!2d49.8332279!3d40.3714578!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307ddf90e6a4ed%3A0xc7f040f01e503b8b!2s32%20Gozel%20(32%20g%C3%B6z%C9%99l)%20stomatoloji%20klinikasi!5e0!3m2!1str!2saz!4v1680168420099!5m2!1str!2saz"
                height="300px"
                id="address"
                width="100%"
                allowFullScreen={true}
                style={{border: 'none'}}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade" />
        </div>
    )
}

export default Address
