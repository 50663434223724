import './education.scss'
import RandevuForm from '../randevuForm'
import EduWork from '../eduWork'
const Education = ({education}) => {
    console.log(education)
    return (
        <div className='education'>
            <EduWork info='Təhsili:' education={education}/>
            <div className='randevu'>
                <RandevuForm />
            </div>
        </div>
    )
}

export default Education
