import './doctorComp.scss'
const DoctorComp = ({doctor}) => {
    return (
        <div className='doctor-comp'>
            <div className="left">
                {
                    doctor?.photo
                        ?
                        <img src={`https://api.32gozel.az/${doctor?.photo}`} alt=""/>
                        :
                        <div className='null-photo'>{doctor?.name?.[0] + '' + doctor?.surname?.[0]}</div>
                }
            </div>
            <div className="right">
                <div className="doctor-info">
                    <div className="dr">Dr.</div>
                    <div className="name">{doctor.name + ' ' + doctor.surname}</div>
                    <div className='desc'>{doctor.duty_label}</div>
                </div>
            </div>
        </div>
    )
}

export default DoctorComp
