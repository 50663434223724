import './index.scss'
import React, {useEffect, useState} from 'react'
import ArrowRight from '../../../assets/images/Group.png'
import ServicesCart from '../servicesCart'
import axios from 'axios'

const Services = () => {
    const [all, setAll] = useState(false)
    const [workList, setWorkList] = useState([])
    const services = async() => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/services`)
        setWorkList(data?.data?.data)
    }
    useEffect(() => {
        services()
    }, [])
    const styleScss = {
        background:'#E23930',
        color:'#fff',
        flexDirection:'row'
    }
    return (
        <div className="services" id='services'>
            <div className="top">
                <div className="content">
                    <div className="left">Xidmətlərimiz
                    </div>
                </div>
            </div>
            <div className="bottom">
                <ServicesCart workList={workList} style={styleScss} width={240} />

            </div>
            <div className="mob-bottom">
                {
                    all
                        ?
                        <ServicesCart  workList={workList} style={styleScss} width={240} />
                        :
                        <ServicesCart workList={workList.slice(0, 3)} style={styleScss} width={240} />
                }
                {
                    !all && workList.length > 3 &&
                    <div className="all" onClick={() => setAll(true)} >
                        Bütün xidmətlərə bax <img src={ArrowRight}/>
                    </div>
                }

            </div>
        </div>
    )
}

export default Services
