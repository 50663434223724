import Close from '../../../assets/images/close.png'
import './sidebar.scss'
import {NavLink} from 'react-router-dom'

const Sidebar = ({menu, openClose, location}) =>  {
    return (
        <div className="menu-card">
            <div className="close" onClick={openClose}>
                <img src={Close} alt=""/>
            </div>
            <ul className="menu-title">
                {menu.map(item => (
                    !item?.path?.includes('#') ? <li key={item.path} onClick={openClose}>
                        <NavLink to={item.path}>{item.title}</NavLink>
                    </li> : (
                        <li key={item.path}>
                            <a href={item.path}
                                className={`/${location.hash}` === item.path && 'active'}>{item.title}</a>
                        </li>
                    )
                ))}
            </ul>
            <a className="mob-button" onClick={openClose} href="/#online-randevu">
                Onlayn randevu
            </a>
        </div>
    )
}

export default Sidebar
