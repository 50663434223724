import Adress from '../../components/pages/address'
import Randevu from '../../components/pages/randevu'
import Hero from '../../components/pages/hero'
import ShortServices from '../../components/pages/shortServices'
import Services from '../../components/pages/services'

const Home = () => {
    return (
        <>
            <Adress/>
            <Hero/>
            <ShortServices/>
            <Services/>
            <Randevu/>

        </>
    )
}

export default Home
